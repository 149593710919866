import React from "react";
import { Link, graphql } from "gatsby";
import {
  Container,
  Box,
  Grid,
  Card,
  Flex,
  Text,
  useThemeUI,
  useColorMode,
} from "theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";
import Img from "gatsby-image";
import Layout from "../components/layout";

const Blog = (props) => {
  const context = useThemeUI();
  const [colorMode, setColorMode] = useColorMode();
  console.log(props.data);
  return (
    <>
      <Layout>
        <h2>Welcome to Kenny's Blog!</h2>
        <Grid sx={{}}>
          {props.data.allMarkdownRemark.edges.map(({ node }) => {
            let img = node.frontmatter.icon.childImageSharp.fluid;
            return (
              <Link to={node.fields.slug}>
                <Card
                  sx={{
                    bg: "primary",
                    /* bg: */
                    /*   colorMode === `dark` */
                    /*     ? lighten(getColor(context.theme, "primary"), 0.1) */
                    /*     : darken(getColor(context.theme, "primary"), 0.1), */
                    width: "100%",
                    height: "180px",
                    borderRadius: "lg",
                    px: 4,
                    boxShadow: `lg`,
                    py: 4,
                    color: `white !important`,
                    transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
                    "&:hover": {
                      transform: `translateY(-5px)`,
                      boxShadow: `xl`,
                    },
                  }}
                >
                  <Flex sx={{ width: "100%", alignItems: "center" }}>
                    <Box sx={{ flex: "1" }}>
                      <Text
                        sx={{
                          textTransform: `uppercase`,
                          letterSpacing: `wide`,
                          fontSize: [3, 4],
                          fontWeight: `medium`,
                          lineHeight: 1,
                        }}
                      >
                        {node.frontmatter.title}
                      </Text>
                      <Text
                        sx={{
                          pt: 3,
                          textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)`,
                        }}
                      >
                        {node.frontmatter.description}
                      </Text>
                    </Box>
                    <Box>
                      <Img style={{ width: "100px" }} fluid={img} />
                    </Box>
                  </Flex>
                </Card>
              </Link>
            );
          })}
        </Grid>
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { layout: { eq: "projects" } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            author
            date
            title
            layout
            description
            icon {
              childImageSharp {
                fluid(maxWidth: 3000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Blog;
