import React from "react";
import { Flex, Box, Text, Button, useColorMode, useThemeUI } from "theme-ui";
import { darken, lighten, getColor } from '@theme-ui/color'
import { Link } from "gatsby"

const style = { cursor: "pointer" };


const BackButton = () => {
    return (
        <Link to="/blog">
        <Button bg="highlight"style={{cursor: "pointer"}}>
            Back
          </Button>
        </Link>
    );
};

const Navbar = ({ parallax, showBackButton }) => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
    const context = useThemeUI()

  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`);
  };

    const borderColor = darken(getColor(context.theme, "gray"), 0.2)

  return (
    <>
      <Flex
        as="nav"
        sx={{
          bg:"gray",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: borderColor,
          alignItems: "center",
          /* position: "fixed", */
          width: "100%",
          top: 0,
          zIndex: 999,
            borderRadius: 4
        }}
      >
        <Box p={3} >
          <Link to="/">
            <Button style={style}>
              Kenny Lov
            </Button>
          </Link>
        </Box>
        <Box sx={{flex: "1 1 auto"}}>
          {showBackButton ?
           <BackButton/>: null
          }
        </Box>
        <Box p={3} mr={20}>
          <Button style={style} onClick={toggleColorMode}>
            {isDark ? "Dark" : "Light"}
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default Navbar;
