import React from 'react'
import { Container } from "theme-ui";
import Navbar from '../components/navbar-blog.tsx'

const Layout = (props) => {
    return(
        <Container sx={{
            mt: 2,
        }}>
          <Navbar
            showBackButton={props.showBackButton}
          />
          {props.children}
        </Container>
    )
}

export default Layout
